import React, { useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form-new';
import SelectDirection from '../../../components/SelectDirection/SelectDirection';
import SelectLanguage from '../../../components/SelectLanguage/SelectLanguage';
import { CertDirection } from '../../../enums/cert-direction';
import { MAX_USER_NAME_LENGTH, MIN_TEXT_INPUT_LENGTH } from '../../../global-constants';
import { SelectOption } from '../../../models/select-option';

import {
    MIN_QUANTITY_TRAINING_GROUP,
    MAX_QUANTITY_TRAINING_GROUP,
    MIN_QUANTITY_TIMES_APPLIES,
    MAX_QUANTITY_TIMES_APPLIES,
} from './constants';
import { DateRange } from './DateRange/DateRange';
import { StudyGroupState } from '../store/store';
import { actions } from '../store/actions';
import { Container, Column, Title, Button, TextInput, Loader } from 'components';
import { StudentList } from './StudentList/StudentList';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AutoCompliteForm } from 'components/AutoComplite/AutoCompliteForm';

interface FormProps {
    id?: string;
    title: string;
    onSubmit: (value: StudyGroupFormData) => void;
    goBack: () => void;
    buttonSubmitTitle?: string;
    buttonCancelTitle?: string;
}

export interface StudyGroupFormData {
    name: string;
    quantityTrainingGroup: string;
    quantityTimesApplies: string;
    selectedTest: string;
    selectedStudyCenter: string;
    dateStartSession: string;
    dateEndSession: string;
}

type StudyGroupFormProps = StudyGroupState & typeof actions & FormProps;

export const StudyGroupForm = (props: StudyGroupFormProps) => {
    const {
        editableStudyGroup,
        goBack,
        onSubmit,
        title,
        id,
        buttonCancelTitle = 'Отмена',
        buttonSubmitTitle = 'Сохранить',
        tests,
        studyCenters,
        getTests,
        selectedLanguage,
        setLanguage,
    } = props;

    const { quantityTimesApplies, dateEnd, dateStart, listOfStudents } = editableStudyGroup;

    const methods = useForm<StudyGroupFormData>();
    const { register, control, handleSubmit, formState } = methods;
    const { isSubmitting, errors } = formState;
    const [t] = useTranslation(['admin-validation', 'info', 'validation']);
    const history = useHistory();

    const [directionId, setDirectionId] = useState<CertDirection>(CertDirection.CyberSecurity);
    const [selectedTestOption, setSelectedTestOption] = useState<SelectOption>();
    const [selectedStudyCenterOption, setSelectedStudyCenterOption] = useState<SelectOption>();

    useEffect(() => {
        if (editableStudyGroup) {
            setSelectedTestOption({
                value: editableStudyGroup.testId as number,
                label: editableStudyGroup.testName as string,
            });
            return;
        }
    }, [tests, editableStudyGroup]);

    useEffect(() => {
        setSelectedStudyCenterOption(studyCenters.find((x) => x.value === editableStudyGroup.studyCenterId));
    }, [studyCenters, editableStudyGroup]);

    useEffect(() => {
        if (id) {
            return;
        }

        getTests({ directionId, language: selectedLanguage });
    }, [directionId, selectedLanguage, id]);

    if (id && !editableStudyGroup.id) {
        return <Loader />;
    }

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText={title} />
            </Column>
            <Column columnSize="100">
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="form-group">
                            <Column columnSize="50">
                                <div className="top-space-3x">
                                    <TextInput
                                        defaultValue={editableStudyGroup?.name}
                                        label={t('info:first-name')}
                                        id="name"
                                        reg={register('name', {
                                            minLength: {
                                                value: MIN_TEXT_INPUT_LENGTH,
                                                message: t('validation:min-length', {
                                                    minLength: MIN_TEXT_INPUT_LENGTH,
                                                }),
                                            },
                                            maxLength: {
                                                value: MAX_USER_NAME_LENGTH,
                                                message: t('validation:max-length', {
                                                    maxLength: MAX_USER_NAME_LENGTH,
                                                }),
                                            },
                                            required: {
                                                value: true,
                                                message: t('validation:required-field'),
                                            },
                                        })}
                                        errorMessage={errors.name?.message as string}
                                    />
                                </div>
                                {!id && (
                                    <div className="top-space-3x">
                                        <TextInput
                                            type="number"
                                            id="quantityTrainingGroup"
                                            label="Кол-во учебных групп"
                                            reg={register('quantityTrainingGroup', {
                                                required: {
                                                    value: true,
                                                    message: t('required'),
                                                },
                                                min: {
                                                    value: MIN_QUANTITY_TRAINING_GROUP,
                                                    message: t('negative-number'),
                                                },
                                                max: {
                                                    value: MAX_QUANTITY_TRAINING_GROUP,
                                                    message: t('max-quantity-training-groups', {
                                                        max: MAX_QUANTITY_TRAINING_GROUP,
                                                    }),
                                                },
                                            })}
                                            errorMessage={errors.quantityTrainingGroup?.message as string}
                                            defaultValue={MIN_QUANTITY_TRAINING_GROUP}
                                        />
                                    </div>
                                )}

                                {!id && (
                                    <fieldset className="form-group top-space-3x">
                                        <div className="form-field">
                                            <SelectDirection
                                                selectedDirectionId={directionId}
                                                onChange={setDirectionId}
                                            />
                                        </div>
                                    </fieldset>
                                )}

                                {!id && (
                                    <div className="top-space-3x">
                                        <SelectLanguage
                                            hasEmpty
                                            onChange={setLanguage}
                                            selectedLanguage={selectedLanguage}
                                            additionalClasses="form-field"
                                        />
                                    </div>
                                )}
                                <div className="top-space-3x">
                                    <Controller
                                        name="selectedTest"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t('required-select'),
                                            },
                                        }}
                                        render={({ field: { onChange } }) => (
                                            <AutoCompliteForm
                                                additionalClasses="form-field study-group-select-test"
                                                label="Выберите тест*"
                                                isDisabled={!!id}
                                                name="selectedTest"
                                                id={'selectedTest'}
                                                placeholder="Не выбрано"
                                                onChange={(v) => {
                                                    setSelectedTestOption({
                                                        value: v?.id ?? '',
                                                        label: v?.name ?? '',
                                                    });
                                                    onChange(v?.id);
                                                }}
                                                values={{
                                                    id: selectedTestOption?.value ?? '',
                                                    name: selectedTestOption?.label ?? '',
                                                }}
                                                dataArr={tests.map((r) => ({ id: r.value, name: r.label }))}
                                                errorMessage={t(errors.selectedTest?.message as string)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="top-space-3x">
                                    <Controller
                                        name="selectedStudyCenter"
                                        control={control}
                                        render={({ field: { onChange } }) => (
                                            <AutoCompliteForm
                                                placeholder="Не выбрано"
                                                isDisabled={!!id}
                                                name="selectedStudyCenter"
                                                id={'selectedStudyCenter'}
                                                label="Выберите учебный центр"
                                                additionalClasses="form-field study-group-select-test"
                                                values={{
                                                    id: selectedStudyCenterOption?.value ?? '',
                                                    name: selectedStudyCenterOption?.label ?? '',
                                                }}
                                                dataArr={studyCenters.map((r) => ({ id: r.value, name: r.label }))}
                                                onChange={(selectedStudyCenter) => onChange(selectedStudyCenter?.id)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="top-space-3x">
                                    <TextInput
                                        id="quantityTimesApplies"
                                        label="Количество мест в сессии*"
                                        defaultValue={quantityTimesApplies}
                                        type="number"
                                        reg={register('quantityTimesApplies', {
                                            min: {
                                                value: MIN_QUANTITY_TIMES_APPLIES,
                                                message: t('negative-number'),
                                            },
                                            max: {
                                                value: MAX_QUANTITY_TIMES_APPLIES,
                                                message: t('max-quantity-times-applies', {
                                                    max: MAX_QUANTITY_TIMES_APPLIES,
                                                }),
                                            },
                                            required: {
                                                value: true,
                                                message: 'validation:required-field',
                                            },
                                        })}
                                        errorMessage={t(errors.quantityTimesApplies?.message as string)}
                                    />
                                </div>
                                <DateRange dateEnd={dateEnd} dateStart={dateStart} />
                            </Column>
                        </div>
                        {id && listOfStudents && listOfStudents.length > 0 && (
                            <StudentList title="Список зарегистрированных участников" students={listOfStudents} />
                        )}
                        <div className="study-group-form-buttons">
                            <Button buttonClass="primary" type="submit" isDisabled={isSubmitting}>
                                {buttonSubmitTitle}
                            </Button>
                            <Button
                                handleClick={() => {
                                    if (history.action !== 'POP') {
                                        return history.goBack();
                                    }
                                    return goBack();
                                }}
                                buttonClass="secondary"
                            >
                                {buttonCancelTitle}
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            </Column>
        </Container>
    );
};
