import React, { InputHTMLAttributes } from 'react';

import { Icon } from '../../Icon';
import { Label } from '../Label';
import { UseFormRegisterReturn } from 'react-hook-form-new';

interface Option {
    label: string;
    value: string | number;
}

interface ToggleProps {
    label?: string;
    defaultValue: number;
    firstOption: Option;
    secondOption: Option;
    reg?: UseFormRegisterReturn;
}

export const Toggle = (props: ToggleProps & InputHTMLAttributes<HTMLInputElement>) => {
    const { name, reg, firstOption, secondOption, defaultValue, label } = props;

    return (
        <>
            {label && (
                <div className="bottom-space-3x">
                    <Label labelText={label} labelFor={name} />
                </div>
            )}

            <label className="toggle">
                <input
                    id="first-option"
                    type="radio"
                    name={name}
                    defaultChecked={defaultValue === firstOption.value}
                    value={firstOption.value}
                    {...reg}
                />
                <input
                    id="second-option"
                    type="radio"
                    name={name}
                    defaultChecked={defaultValue === secondOption.value}
                    value={secondOption.value}
                    {...reg}
                />
                <span className="toggle-slider">
                    <label htmlFor="first-option" className="first-option">
                        {firstOption.label}
                    </label>
                    <label htmlFor="second-option" className="second-option">
                        {secondOption.label}
                    </label>
                    <Icon iconName="check-mark" />
                </span>
            </label>
        </>
    );
};
