import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { Container, Column, Title, TextInput, Button, UploadButton, Label } from 'components';
import SelectDirection from '../../../components/SelectDirection/SelectDirection';
import { CertDirection } from '../../../enums/cert-direction';
import { Category } from '../../../models/category';
import { CategoriesState } from '../store/store';
import { actionCreators } from '../store/actions';
import { MAX_LENGTH_CATEGORY_NAME } from '../constants';

export interface FormValue {
    name: string;
    directionId: CertDirection;
    file?: File[];
}

interface CategoryFormProps {
    title: string;
    onSubmit: (value: FormValue) => void;
    handleClickCancel: () => void;
}

export type CategoryStoreProps = CategoriesState & typeof actionCreators;

export const CategoryForm = (props: CategoryStoreProps & CategoryFormProps) => {
    const { onSubmit, handleClickCancel, categoryForEdit, title, categories, categoryError, clearMessageCategory } =
        props;
    const { id } = categoryForEdit || {};
    const { register, handleSubmit, setValue, setError, formState, control } = useForm<FormValue>();
    const { isSubmitting, errors } = formState;
    const [t] = useTranslation('admin-validation');

    useEffect(() => {
        if (categoryForEdit?.name) {
            setValue('name', categoryForEdit?.name);
        }
    }, [categoryForEdit, setValue]);

    useEffect(() => {
        register('file');
    }, [register]);

    useEffect(() => {
        if (categoryError) {
            setError('name', {
                message: categoryError,
                type: '',
            });
        }
        return () => {
            clearMessageCategory();
        };
    }, [props, categoryError, setError, clearMessageCategory]);

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText={title} />
            </Column>
            <Column columnSize="50">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <TextInput
                        label="Название категории"
                        id="name"
                        errorMessage={errors.name?.message as string}
                        reg={register('name', {
                            required: {
                                value: true,
                                message: t('required'),
                            },
                            maxLength: {
                                value: MAX_LENGTH_CATEGORY_NAME,
                                message: t('max-length', {
                                    maxLength: MAX_LENGTH_CATEGORY_NAME,
                                }),
                            },
                            validate: (value) => getNameValidationMessage(value, categories.items, t, id) || true,
                        })}
                    />

                    <fieldset className="form-group top-space-3x">
                        <Controller
                            control={control}
                            name="directionId"
                            defaultValue={CertDirection.CyberSecurity}
                            render={({ field: { onChange } }) => (
                                <SelectDirection
                                    isDisabled={!!categoryForEdit}
                                    selectedDirectionId={categoryForEdit?.directionId || CertDirection.CyberSecurity}
                                    onChange={(id) => onChange(id)}
                                />
                            )}
                        />
                    </fieldset>

                    <Label
                        labelFor="upload-button"
                        labelText="Загрузите иконку в формате .svg для белого фона"
                        additionalClasses="upload-button-label top-space-3x"
                    />
                    <UploadButton
                        type="image"
                        name="file"
                        setValue={(_, v) => setValue('file', v)}
                        accept="image/svg+xml"
                        defaultValue={
                            categoryForEdit && {
                                link: categoryForEdit.iconLink,
                                name: categoryForEdit.iconFileLink,
                            }
                        }
                    />
                    <Button buttonClass="primary" type="submit" isDisabled={isSubmitting}>
                        Сохранить
                    </Button>
                    <Button handleClick={handleClickCancel} buttonClass="secondary">
                        Отмена
                    </Button>
                </form>
            </Column>
        </Container>
    );
};

const getNameValidationMessage = (value: string, categories: Category[], t: TFunction, id?: number) =>
    categories.some((x) => x.name.trim() === value?.trim() && x.id !== id) ? t('required-category-name') : '';
