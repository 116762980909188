import React, { ReactNode } from 'react';

import { Loader } from 'components';

export const withLoading =
    <TValue extends { isLoading: boolean }>(
        Component: (props: TValue) => JSX.Element | null | undefined | string | number | boolean | Iterable<ReactNode>
    ) =>
    (props: TValue) =>
        (
            <>
                {props.isLoading && <Loader />}
                {
                    /// @ts-ignore
                    Component && <Component {...props} />
                }
            </>
        );
