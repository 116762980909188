import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-new';
import { connect } from 'react-redux';

import { Container, Column, Title, Button } from 'components';
import { ApplicationState } from 'store';
import { AdminAuthenticationState, actions, UpdateAdmin } from 'authentication/AdminAuthenticationStore';
import { withLoading } from 'utils';
import { TextFieldInput } from '../info/enter-name/TextFieldInput';

type EnterNameProps = AdminAuthenticationState & typeof actions;

const UpdateAdminProfile = (props: EnterNameProps) => {
    const { admin, updateAdmin } = props;
    const [t] = useTranslation(['info', 'validation']);
    const { register, handleSubmit, setValue, formState } = useForm<UpdateAdmin>();
    const { isSubmitting, errors } = formState;

    const onSubmit = handleSubmit(async (values: UpdateAdmin) => {
        await updateAdmin(values);
    });

    useEffect(() => {
        if (admin?.name) {
            setValue('userName', admin?.name);
        }
        if (admin?.lastName) {
            setValue('lastName', admin?.lastName);
        }
        if (admin?.middleName) {
            setValue('middleName', admin?.middleName);
        }
    }, [setValue, admin]);

    return (
        <Container>
            <Column columnSize="100" className="code-column">
                <Title titleText={t('title')} />
                <form onSubmit={onSubmit} autoComplete="off" className="info-form">
                    <TextFieldInput
                        name="userName"
                        label={t('first-name')}
                        isRequired
                        register={register}
                        errors={errors}
                    />
                    <TextFieldInput
                        name="lastName"
                        label={t('last-name')}
                        isRequired
                        register={register}
                        errors={errors}
                    />
                    <TextFieldInput
                        name="middleName"
                        label={t('middle-name')}
                        isRequired={false}
                        register={register}
                        errors={errors}
                    />
                    <div className="info-buttons d-flex justify-content-space-between">
                        <Button type="submit" buttonClass="primary" isDisabled={isSubmitting}>
                            {t('save-button')}
                        </Button>
                    </div>
                </form>
            </Column>
        </Container>
    );
};

export default connect(
    ({ adminAuthentication }: ApplicationState) => ({
        ...adminAuthentication,
    }),
    { ...actions }
)(withLoading(UpdateAdminProfile) as () => JSX.Element);
