import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';
import { Test } from '../../../../models/test/test';
import {
    MAX_TEST_TIME,
    MIN_TEST_TIME,
    MAX_TEST_NAME_LENGTH,
    MIN_QUANTITY_ATTEMPTS,
    MAX_QUANTITY_ATTEMPTS,
} from '../constants';
import { TextInput } from 'components';
import SelectLanguage from '../../../../components/SelectLanguage/SelectLanguage';
import { LanguageEnum } from '../../../../enums/language.enum';

interface SettingInputsProps {
    editableTest: Test;
}

export const SettingInputs = (props: SettingInputsProps) => {
    const { editableTest } = props;
    const { maxQuantityAttempt, maxQuantityMinutes, title, testLanguage } = editableTest;
    const {
        register,
        setValue,
        control,
        formState: { errors },
    } = useFormContext();
    const [t] = useTranslation('admin-validation');

    useEffect(() => {
        setValue('title', title);
        setValue('quantityMinutes', maxQuantityMinutes);
        setValue('quantityAttempt', maxQuantityAttempt);
    }, [maxQuantityAttempt, maxQuantityMinutes, title, setValue]);

    return (
        <>
            <TextInput
                label="Название теста"
                id="title"
                reg={register('title', {
                    required: {
                        value: true,
                        message: t('required'),
                    },
                    maxLength: {
                        value: MAX_TEST_NAME_LENGTH,
                        message: t('max-length', { maxLength: MAX_TEST_NAME_LENGTH }),
                    },
                })}
                errorMessage={errors.title?.message as string}
            />
            <TextInput
                type="number"
                label="Длительность теста (в минутах)"
                id="quantityMinutes"
                reg={register('quantityMinutes', {
                    required: {
                        value: true,
                        message: t('required'),
                    },
                    min: {
                        value: MIN_TEST_TIME,
                        message: t('incorrect-input'),
                    },
                    max: {
                        value: MAX_TEST_TIME,
                        message: t('max-test-time', { max: MAX_TEST_TIME }),
                    },
                })}
                errorMessage={errors.quantityMinutes?.message as string}
            />
            <TextInput
                type="number"
                label="Максимальное число попыток"
                id="quantityAttempt"
                reg={register('quantityAttempt', {
                    required: {
                        value: true,
                        message: t('required'),
                    },
                    min: {
                        value: MIN_QUANTITY_ATTEMPTS,
                        message: t('incorrect-input'),
                    },
                    max: {
                        value: MAX_QUANTITY_ATTEMPTS,
                        message: t('max-quantity-attempt', { max: MAX_QUANTITY_ATTEMPTS }),
                    },
                })}
                errorMessage={errors.quantityAttempt?.message as string}
            />
            <div className="top-space-3x">
                <Controller
                    rules={{
                        required: {
                            value: true,
                            message: 'required',
                        },
                    }}
                    defaultValue={testLanguage ?? LanguageEnum.English}
                    control={control}
                    name="testLanguage"
                    render={({ field: { onChange } }) => (
                        <SelectLanguage
                            onChange={(lang) => onChange(lang)}
                            selectedLanguage={testLanguage ?? LanguageEnum.English}
                            additionalClasses="form-field "
                        />
                    )}
                />
            </div>
        </>
    );
};
