import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';

import { Title, Button, TextInput } from 'components';
import { UserData } from 'pages/info/store/store';
import { MIN_TEXT_INPUT_LENGTH, MAX_USER_FULL_NAME_LENGTH } from 'global-constants';

interface EditCertificateDataProps {
    userName?: string;
    setUserName: (value: string) => void;
    setIsEdit: (value: boolean) => void;
}

export const EditCertificateData = (props: EditCertificateDataProps) => {
    const { userName, setUserName, setIsEdit } = props;
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<UserData>();
    const [t] = useTranslation(['confirm-certificate-data', 'validation']);

    useEffect(() => {
        if (userName) {
            setValue('userName', userName);
        }
    }, [setValue, userName]);

    const handleSubmitForm = handleSubmit((value: UserData) => {
        setUserName(value.userName);
        setIsEdit(false);
    });

    return (
        <>
            <Title titleText={t('edit-title')} />
            <div>
                <form onSubmit={handleSubmitForm} autoComplete="off">
                    <TextInput
                        label={t('input-label')}
                        id="userName"
                        reg={register('userName', {
                            required: {
                                value: true,
                                message: t('validation:required-field'),
                            },
                            minLength: {
                                value: MIN_TEXT_INPUT_LENGTH,
                                message: t('validation:min-length', {
                                    minLength: MIN_TEXT_INPUT_LENGTH,
                                }),
                            },
                            maxLength: {
                                value: MAX_USER_FULL_NAME_LENGTH,
                                message: t('validation:max-length', {
                                    maxLength: MAX_USER_FULL_NAME_LENGTH,
                                }),
                            },
                        })}
                        errorMessage={errors.userName?.message as string}
                    />
                    <Button type="submit" buttonClass="primary">
                        {t('submit')}
                    </Button>
                    <Button buttonClass="secondary" handleClick={() => setIsEdit(false)}>
                        {t('cancel')}
                    </Button>
                </form>
            </div>
        </>
    );
};

export default EditCertificateData;
