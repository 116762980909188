import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { useTheme, theme } from 'utils';
import TestFinish from './test-finish/TestFinish';
import PassingTest from './passing-test/PassingTest';
import { actions, UserTestState } from './store';
import { ApplicationState } from 'store';

type TestPagesProps = UserTestState & typeof actions;

const TestPages = (props: TestPagesProps) => {
    const { clearTest } = props;
    const { path } = useRouteMatch();
    const { changeTheme } = useTheme(theme.light);
    useTheme(theme.noSideBarMenu);
    useTheme(theme.hideLanguagePicker);

    useEffect(
        () => () => {
            clearTest();
        },
        [clearTest]
    );

    return (
        <Switch>
            <Route exact path={`${path}/finish`}>
                <TestFinish />
            </Route>
            <Route exact path={`${path}/passing/:questionId`}>
                <PassingTest changeLightTheme={changeTheme} />
            </Route>
        </Switch>
    );
};

export default connect((state: ApplicationState) => state.userTest, actions)(TestPages as () => JSX.Element);
