import React, { FC, useEffect, useState } from 'react';
import { useUrlQueryParamsForGrid } from 'hooks/useUrlQueryParamsForGrid';
import { ResponsePagination } from 'models/response-pagination';
import { DownloadFileService } from '../../services/download-file.service';
import api from 'api';
import { Button, Column, Container, Label, Loader, TextInput, Title } from 'components';
import { AdditionalCertificate } from './models/additional-certificate';
import { GetCertificates } from './models/get-certificates';
import { AdditionalCertificateGrid } from './additional-certificates-grid';
import { useAlert } from 'react-alert';
import { AdditionalCertificateLevel } from './models/additional-certificate-level';
import SelectByEnum from 'components/SelectByEnum/SelectByEnum';
import { CertificationTypeFilter } from 'enums/certification-type';
import { LevelSettings } from './models/level-settings';
import { SettingsGrid } from './settings-grid';
import { UpdateLevelSettingsArea } from './models/update-level-settings-area';
import { Item } from 'components/AutoComplite/AutoComplite';
import { TestArea } from 'pages/test-areas/models/AreaModels';
import './additional-certificates.scss';

const AdditionalCertificateComponent: FC = () => {
    const [isLoading, setLoading] = useState(false);
    const alert = useAlert();
    const [count, setCount] = useState(0);
    const [data, setData] = useState<Array<AdditionalCertificate>>([]);

    const [email, setEmail] = useState<string>('');
    const [emailSearch, setEmailSearch] = useState<string>('');

    const [level, setLevel] = useState<AdditionalCertificateLevel | undefined>();
    const [levelSearch, setLevelSearch] = useState<AdditionalCertificateLevel | undefined>();

    const [isActive, setIsActive] = useState<CertificationTypeFilter | undefined>();
    const [isActiveSearch, setIsActiveSearch] = useState<CertificationTypeFilter | undefined>();

    const [settings, setSettings] = useState<LevelSettings[]>([]);
    const [areas, setAreas] = useState<Item[]>([]);

    const getSettings = () => {
        api.get<LevelSettings[]>(`/LevelSettings/GetSettings`).then((data) => {
            setSettings(data.data);
        });
    };

    const getTestArea = () => {
        api.get<ResponsePagination<TestArea>>('/TestArea/', {
            params: {
                page: 0,
                size: 150,
                str: '',
            },
        }).then((d) => {
            setAreas(d.data.items);
        });
    };

    useEffect(() => {
        getSettings();
        getTestArea();
    }, []);

    const getParamsWithoutPagination = () => ({
        level: levelSearch,
        email: emailSearch,
        isActive: isActiveSearch,
    });

    const handleSearch = () => {
        setLoading(true);
        api.get<ResponsePagination<AdditionalCertificate>>(`/LevelSettings/GetCertificates`, {
            params: getParams(),
        })
            .then((d) => {
                setCount(d.data.count);
                setData(d.data.items);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const { pagination } = useUrlQueryParamsForGrid({
        total: count,
        clearPaginationDeps: Object.values(getParamsWithoutPagination()),
        loadFn: handleSearch,
    });

    const getParams = (): GetCertificates => ({
        ...getParamsWithoutPagination(),
        page: pagination.page,
        size: pagination.size,
    });

    const downloadXls = () => {
        setLoading(true);
        DownloadFileService.download(`/LevelSettings/GetExcel`, {
            ...getParamsWithoutPagination(),
            page: 0,
            size: 0,
        }).finally(() => {
            setLoading(false);
        });
    };

    const search = () => {
        setEmailSearch(email);
        setIsActiveSearch(isActive);
        setLevelSearch(level);
    };

    const claer = () => {
        setEmailSearch('');
        setIsActiveSearch(undefined);
        setLevelSearch(undefined);

        setEmail('');
        setIsActive(undefined);
        setLevel(undefined);
    };

    const save = () => {
        setLoading(true);
        api.post<ResponsePagination<AdditionalCertificate>>(
            `/LevelSettings/Update`,
            settings.map((s) => ({
                level: s.level,
                areasId: s.areas.map((a) => a.id),
            }))
        )
            .then(() => {
                getSettings();
                handleSearch();
                alert.success('Данные обновлены', { timeout: 10000 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateSetting = (model: UpdateLevelSettingsArea) => {
        const newVal = [...settings];
        newVal[model.index] = {
            level: model.level,
            areas: model.testAreas.map((m) => ({ id: m.id, name: m.name, creationDate: '' })),
        };
        setSettings(newVal);
    };

    return (
        <>
            {isLoading && <Loader isBlock />}
            {data && (
                <Container>
                    <Column columnSize="50">
                        <Title titleText="Дополнительные сертификаты" />
                    </Column>

                    {settings && areas && (
                        <Container additionalClass="sf-container">
                            <Column columnSize="100" className="p-right-space-3x top-space-10x">
                                <SettingsGrid data={settings} updateSetting={updateSetting} />
                            </Column>
                            <Column columnSize="50" className="text-left bottom-space-3x top-space-3x">
                                <Button buttonClass="primary" handleClick={save}>
                                    Сохранить
                                </Button>
                            </Column>
                        </Container>
                    )}
                    <Column columnSize="100">
                        <hr />
                    </Column>
                    <Container additionalClass="container--items-end">
                        <Column columnSize="30" className="p-right-space-3x top-space-10x">
                            <fieldset className="form-group">
                                <Label labelText="Уровень" labelFor="" />
                                <SelectByEnum
                                    hasEmpty
                                    enumOptions={AdditionalCertificateLevel}
                                    selectedId={level}
                                    onChange={setLevel}
                                />
                            </fieldset>
                        </Column>
                        <Column columnSize="30" className="p-right-space-3x top-space-10x">
                            <fieldset className="form-group">
                                <Label labelText="Сертификат" labelFor="" />
                                <SelectByEnum
                                    hasEmpty
                                    enumOptions={CertificationTypeFilter}
                                    selectedId={isActive}
                                    onChange={setIsActive}
                                />
                            </fieldset>
                        </Column>
                        <Column columnSize="40" className="p-right-space-3x top-space-10x">
                            <TextInput
                                id="emailId"
                                label="Email"
                                value={email}
                                onChangeHandler={(e) => setEmail(e.target.value)}
                            />
                        </Column>
                        <Column columnSize="50" className="text-left bottom-space-3x">
                            <Button buttonClass="primary" handleClick={search}>
                                Поиск
                            </Button>
                            <Button buttonClass="primary" handleClick={claer}>
                                Очистить
                            </Button>
                        </Column>
                    </Container>
                    <Column columnSize="100" className="text-right">
                        <Button buttonClass="primary" handleClick={downloadXls}>
                            Excel
                        </Button>
                    </Column>
                    <Column columnSize="100" className="top-space-10x">
                        <AdditionalCertificateGrid pagination={pagination} data={data} />
                    </Column>
                </Container>
            )}
        </>
    );
};

export default AdditionalCertificateComponent;
