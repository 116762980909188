import React from 'react';
import { useFormContext } from 'react-hook-form-new';

import { AssessmentInput } from './assessment-input/AssessmentInput';
import { TestCategoryForm } from '../../../../models/test/test-category-form';

interface InputProps {
    label: string;
    value: string | number;
}

interface AssessmentInputsProps {
    categories: TestCategoryForm[];
    defaultValue: AssessmentFields;
}
export interface AssessmentFields {
    [key: string]: {
        assessment: InputProps;
        description: InputProps;
    };
}

export const AssessmentInputs = (props: AssessmentInputsProps) => {
    const { defaultValue, categories } = props;
    const { watch } = useFormContext();
    const assessments = watch(Object.keys(defaultValue));

    return (
        <div className="input-group">
            {Object.keys(defaultValue).map((key, index) => (
                <AssessmentInput
                    key={`${key}-${index}`}
                    prevAssessmentValue={Object.values(assessments)?.[index - 1]}
                    nextAssessmentValue={Object.values(assessments)?.[index + 1]}
                    assessment={{
                        label: defaultValue[key].assessment.label,
                        defaultValue: defaultValue[key].assessment.value,
                        fieldName: key,
                    }}
                    description={{
                        label: defaultValue[key].description.label,
                        defaultValue: defaultValue[key].description.value,
                        fieldName: `${key}Description`,
                    }}
                    categories={categories}
                />
            ))}
        </div>
    );
};
