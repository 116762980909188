import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Loader } from '../../components';

import { actions } from './store/actions';
import { TestsState } from './store/store';
import { ApplicationState } from 'store';
import { connectComponent, withLoading } from 'utils';
import { TestForm, TestFormData } from './test-form/TestForm';

type TestForEditProps = TestsState & typeof actions;

const TestFormEdit = (props: TestForEditProps) => {
    const { editableTest, updateTest, getTest, clearEditableTest, testSuccess, testError, clearMessage } = props;
    const { id } = useParams<{ id: string }>();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const alert = useAlert();

    useEffect(() => {
        getTest(+id);
        return () => {
            clearEditableTest();
        };
    }, []);

    const goBack = useCallback(() => {
        push(pathname.replace(`/edit/${id}`, ''));
    }, [pathname, id, push]);

    useEffect(() => {
        if (testSuccess) {
            alert.success(testSuccess);
            goBack();
        } else if (testError) {
            alert.error(testError, { timeout: 10000 });
        }

        clearMessage();
    }, [testSuccess, testError, alert, clearMessage, goBack]);

    const handleSubmit = async (test: TestFormData) => {
        await updateTest({ ...test, id: +id });
    };

    if (!editableTest.id) {
        return <Loader />;
    }

    return <TestForm {...props} title="Редактировать тест" onSubmit={handleSubmit} goBack={goBack} id={id} />;
};

export default connectComponent({
    mapStateToProps: ({ tests }: ApplicationState) => ({
        ...tests,
        isLoading: tests?.isLoading || tests?.isLoadingCategories,
    }),
    actions,
    component: withLoading(TestFormEdit),
});
