import React, { FC, useRef } from 'react';
import { useForm } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';

import {
    PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH,
    PRIVACY_STATEMENT_FULL_PATH,
    PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH,
    TERMS_OF_USE_FULL_PATH,
} from '../../global-constants';
import { StudentStudyCenterAgreements } from '../../models/student-study-center/student-study-center-agreements';
import TermsOfUse from '../../pages/application-policies/terms-of-use/TermsOfUsePage';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Column } from '../Column';
import PrivacyStatementBase from '../../pages/application-policies/privacy-statement/PrivacyBaseComponent';
import { Title } from '../Title';
import DOMPurify from 'isomorphic-dompurify';
import { LanguageKeys } from 'models/language/language';
import { language } from 'constants/language';
DOMPurify.setConfig({ ADD_ATTR: ['target'] });

interface StudentStudyCenterAgreementsProps {
    submit: (agr: StudentStudyCenterAgreements) => void;
    personalVersion: number;
    marketingVersion: number;
}

const StudentStudyCenterAgreementsForm: FC<StudentStudyCenterAgreementsProps> = ({
    submit,
    personalVersion,
    marketingVersion,
}) => {
    const { register, formState, handleSubmit } = useForm<StudentStudyCenterAgreements>();
    const { isSubmitting, errors } = formState;

    const [t, i18n] = useTranslation(['info', 'student-study-center-agr']);
    const currentLanguage = i18n.language as LanguageKeys;

    const refMarketing = useRef<HTMLDivElement>(null);
    const refPersonal = useRef<HTMLDivElement>(null);

    const onSubmit = handleSubmit(async (formValues: StudentStudyCenterAgreements) => {
        submit({
            ...formValues,
            personalCheckBoxText:
                currentLanguage === language.ru.key
                    ? t('info:data-consent-agreement-with-link', {
                          dataConsentLink: `/${PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH}`,
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      })
                    : t('info:data-consent-agreement2', {
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      }),
            personalText: refPersonal?.current?.innerHTML as string,
            salesForceCheckBoxText:
                currentLanguage === language.ru.key
                    ? t('info:marketing-consent-agreement-with-link', {
                          dataConsentLink: `/${PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH}`,
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      })
                    : t('info:marketing-consent-agreement2', {
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      }),
            salesForceText: refMarketing?.current?.innerHTML as string,
            personalVersion,
            marketingVersion,
        });
    });

    return (
        <Column columnSize="100" className="code-column block-center">
            <Title titleText={t('student-study-center-agr:agreement-title')} />
            <div className="confirm-term-of-use">
                <Checkbox
                    additionalClasses={errors?.personalAgreement && 'error'}
                    id="personalAgreement"
                    reg={register('personalAgreement', {
                        required: {
                            value: true,
                            message: '',
                        },
                    })}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            currentLanguage === language.ru.key
                                ? t('info:data-consent-agreement-with-link', {
                                      dataConsentLink: `/${PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH}`,
                                      privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                  })
                                : t('info:data-consent-agreement2', {
                                      privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                  })
                        ),
                    }}
                />
            </div>

            <div className="confirm-term-of-use">
                <Checkbox id="salesForce" reg={register('salesForce')} />
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            currentLanguage === language.ru.key
                                ? t('info:marketing-consent-agreement-with-link', {
                                      dataConsentLink: `/${PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH}`,
                                      privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                  })
                                : t('info:marketing-consent-agreement2', {
                                      privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                  })
                        ),
                    }}
                />
            </div>

            <div className="confirm-term-of-use">
                <span
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            t('save-button-press', {
                                touLink: `/${TERMS_OF_USE_FULL_PATH}`,
                            })
                        ),
                    }}
                />
            </div>

            <div className="info-buttons d-flex justify-content-space-between">
                <Button buttonClass="primary" isDisabled={isSubmitting} handleClick={() => onSubmit()}>
                    {t('info:save-button')}
                </Button>
            </div>

            <div className="hidden" ref={refPersonal}>
                <TermsOfUse />
            </div>
            <div className="hidden" ref={refMarketing}>
                <PrivacyStatementBase />
            </div>
        </Column>
    );
};

export default StudentStudyCenterAgreementsForm;
