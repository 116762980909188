import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';
import { TestCategoryForm } from '../../../../models/test/test-category-form';

import { ValidationMessage, TextInput, EmptyListWarning } from 'components';
import { Category } from './category/Category';
import Pagination from '../../../../components/Pagination/Pagination';
import { CATEGORIES_PAGE_SIZE } from '../../../categories/constants';

interface CategoryListProps {
    categories: TestCategoryForm[];
    checkCategory: (id: number) => void;
    setQuantitySelectedQuestions: (value: number, id: number) => void;
}

export const CategoryList = (props: CategoryListProps) => {
    const { categories, checkCategory, setQuantitySelectedQuestions } = props;
    const { formState, register, trigger } = useFormContext();
    const [t] = useTranslation('admin-validation');
    const { isSubmitted, errors } = formState;
    const quantitySelectedQuestions = getQuantitySelectedQuestion(categories || []);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [length, setLength] = useState(categories.length);
    const [search, setSearch] = useState<string>('');
    const [categoriesSearch, setCategoriesSearch] = useState<TestCategoryForm[]>(categories);
    const [init, setInit] = useState(true);

    useEffect(() => {
        if (!init || !categories || categories.length === 0) return;
        setCategoriesSearch(categories);
        setLength(categories.length);
        setInit(false);
    }, [categories]);

    useLayoutEffect(() => {
        register('category', {
            validate: {
                checkCategoryQuestionCount: () =>
                    quantitySelectedQuestions < 1 ? (t('question-category-list') as string) : true,
            },
        });
    });

    useEffect(() => {
        if (!isSubmitted) {
            return;
        }
        trigger('category');
    }, [isSubmitted, quantitySelectedQuestions]);

    const changeSearch = (str: string) => {
        setCurrentPage(0);
        setSearch(str);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            const allCategories = categories.filter(
                (el) => !search || el.name.toLowerCase().includes(search.toLocaleLowerCase())
            );
            setLength(allCategories.length);
            setCategoriesSearch([
                ...allCategories.slice(currentPage * CATEGORIES_PAGE_SIZE, (currentPage + 1) * CATEGORIES_PAGE_SIZE),
            ]);
        }, 200);

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [search]);

    useEffect(() => {
        const allCategories = categories.filter(
            (el) => !search || el.name.toLowerCase().includes(search.toLocaleLowerCase())
        );
        setLength(allCategories.length);
        setCategoriesSearch([
            ...allCategories.slice(currentPage * CATEGORIES_PAGE_SIZE, (currentPage + 1) * CATEGORIES_PAGE_SIZE),
        ]);
    }, [currentPage]);

    return (
        <fieldset className="category-list" name="questionsCategoryList">
            <TextInput
                id="category_search"
                type="text"
                label="Категории вопросов"
                value={search}
                onChangeHandler={(e) => changeSearch(e.target.value)}
            />
            {categoriesSearch.length === 0 && <EmptyListWarning />}
            <div className="category-list-fields" id="category-list-fields">
                {categoriesSearch.map((x) => (
                    <Category
                        key={`${x.id}`}
                        id={x.id}
                        title={x.name}
                        defaultChecked={x.checked}
                        quantityQuestions={x.quantityQuestions}
                        defaultQuantitySelectedQuestions={x.quantitySelectedQuestions}
                        iconFileLInk={x.iconFileLink}
                        check={checkCategory}
                        setQuantitySelectedQuestions={setQuantitySelectedQuestions}
                    />
                ))}
                <div className="category-list-footer">
                    {errors.questionsCategoryList?.message && (
                        <ValidationMessage>{errors.questionsCategoryList?.message as string}</ValidationMessage>
                    )}

                    {errors.category?.message && (
                        <ValidationMessage>{errors.category?.message as string}</ValidationMessage>
                    )}
                    <div className="quantity">Общее кол-во вопросов в тесте: {quantitySelectedQuestions}</div>
                </div>
            </div>

            {length !== 0 && (
                <Pagination
                    page={currentPage}
                    size={CATEGORIES_PAGE_SIZE}
                    total={length}
                    handleChange={(page: number) => setCurrentPage(page)}
                />
            )}
        </fieldset>
    );
};

const getQuantitySelectedQuestion = (categories: TestCategoryForm[]) => {
    if (categories.some((c) => c.checked && c.quantitySelectedQuestions === 0)) {
        return 0;
    }
    return categories
        .filter((x) => x.checked)
        .reduce((acc, cur) => acc + Number(cur.quantitySelectedQuestions || 0), 0);
};
