import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';
import { emailRegex } from '../../constants/regex';
import {
    MAX_EMAIL_LENGTH,
    MAX_USER_COMPANY_LENGTH,
    MAX_USER_NAME_LENGTH,
    MIN_TEXT_INPUT_LENGTH,
} from '../../global-constants';
import { AddStudyCenterUser } from '../../models/study-center-users/add-study-center-user';
import { StudyCenterUser } from '../../models/study-center-users/study-center-user';
import { Button } from '../Button';
import { TextInput } from '../Form';
import SelectCountry from '../SelectCountry/SelectCountry';
import { Checkbox } from 'components/Checkbox';

interface StudyCenterUserFormProps {
    user?: StudyCenterUser;
    studyCenterId: number;
    submitForm: (user: AddStudyCenterUser) => void;
}

const StudyCenterUserForm: FC<StudyCenterUserFormProps> = (props) => {
    const { user, studyCenterId, submitForm } = props;
    const [t] = useTranslation(['info', 'validation']);
    const methods = useForm<AddStudyCenterUser>({ mode: 'onChange' });
    const { register, handleSubmit, control, formState } = methods;
    const { isSubmitting, isValid, errors } = formState;

    const [isPartner, setIsPartner] = useState(user?.isPartner ?? false);

    const onSubmit = handleSubmit(async (formValues: AddStudyCenterUser) => {
        submitForm({ ...formValues, studyCenterId, isPartner });
    });

    return (
        <form onSubmit={onSubmit} autoComplete="off" className="info-form">
            <div className="top-space-3x">
                <TextInput
                    defaultValue={user?.userName}
                    label={t('first-name')}
                    id="userName"
                    reg={register('userName', {
                        minLength: {
                            value: MIN_TEXT_INPUT_LENGTH,
                            message: t('validation:min-length', {
                                minLength: MIN_TEXT_INPUT_LENGTH,
                            }),
                        },
                        maxLength: {
                            value: MAX_USER_NAME_LENGTH,
                            message: t('validation:max-length', {
                                maxLength: MAX_USER_NAME_LENGTH,
                            }),
                        },
                        required: {
                            value: true,
                            message: 'validation:required-field',
                        },
                    })}
                    errorMessage={t(errors.userName?.message as string)}
                />
            </div>
            <div className="top-space-3x">
                <TextInput
                    defaultValue={user?.lastName}
                    label={t('last-name')}
                    id="lastName"
                    reg={register('lastName', {
                        minLength: {
                            value: MIN_TEXT_INPUT_LENGTH,
                            message: t('validation:min-length', {
                                minLength: MIN_TEXT_INPUT_LENGTH,
                            }),
                        },
                        maxLength: {
                            value: MAX_USER_NAME_LENGTH,
                            message: t('validation:max-length', {
                                maxLength: MAX_USER_NAME_LENGTH,
                            }),
                        },
                        required: {
                            value: true,
                            message: 'validation:required-field',
                        },
                    })}
                    errorMessage={t(errors.lastName?.message as string)}
                />
            </div>
            <div className="top-space-3x">
                <TextInput
                    defaultValue={user?.middleName}
                    label={t('middle-name')}
                    id="middleName"
                    reg={register('middleName', {
                        minLength: {
                            value: MIN_TEXT_INPUT_LENGTH,
                            message: t('validation:min-length', {
                                minLength: MIN_TEXT_INPUT_LENGTH,
                            }),
                        },
                        maxLength: {
                            value: MAX_USER_NAME_LENGTH,
                            message: t('validation:max-length', {
                                maxLength: MAX_USER_NAME_LENGTH,
                            }),
                        },
                        required: {
                            value: false,
                            message: 'validation:required-field',
                        },
                    })}
                    errorMessage={t(errors.middleName?.message as string)}
                />
            </div>
            <div className="top-space-3x">
                <TextInput
                    defaultValue={user?.email}
                    label={t('email')}
                    id="email"
                    reg={register('email', {
                        minLength: {
                            value: MIN_TEXT_INPUT_LENGTH,
                            message: t('validation:min-length', {
                                minLength: MIN_TEXT_INPUT_LENGTH,
                            }),
                        },
                        maxLength: {
                            value: MAX_EMAIL_LENGTH,
                            message: t('validation:max-length', {
                                maxLength: MAX_EMAIL_LENGTH,
                            }),
                        },
                        required: {
                            value: true,
                            message: 'validation:required-field',
                        },
                        pattern: {
                            value: emailRegex,
                            message: 'validation:email-not-valid',
                        },
                    })}
                    errorMessage={t(errors.email?.message as string)}
                />
            </div>
            <fieldset className="form-group top-space-3x">
                <Checkbox
                    id="isPartner"
                    label={t('partner')}
                    isChecked={isPartner}
                    handleChange={() => setIsPartner(!isPartner)}
                />
            </fieldset>
            <div className="top-space-3x">
                <TextInput
                    defaultValue={user?.company}
                    label={t('company')}
                    id="company"
                    reg={register('company', {
                        minLength: {
                            value: MIN_TEXT_INPUT_LENGTH,
                            message: t('validation:min-length', {
                                minLength: MIN_TEXT_INPUT_LENGTH,
                            }),
                        },
                        maxLength: {
                            value: MAX_USER_COMPANY_LENGTH,
                            message: t('validation:max-length', {
                                maxLength: MAX_USER_COMPANY_LENGTH,
                            }),
                        },
                        required: {
                            value: true,
                            message: 'validation:required-field',
                        },
                    })}
                    errorMessage={t(errors.company?.message as string)}
                />
            </div>

            <fieldset className="form-group top-space-3x">
                <label className="form-label">{t('country')}</label>
                <Controller
                    control={control}
                    name="countryId"
                    defaultValue={user?.country?.id || 1}
                    render={({ field: { onChange } }) => (
                        <SelectCountry selectedId={user?.country?.id || 1} onChange={(id) => onChange(id)} />
                    )}
                />
            </fieldset>

            <div className="info-buttons d-flex justify-content-space-between">
                <Button type="submit" buttonClass="primary" isDisabled={isSubmitting || !isValid}>
                    {t('save-button')}
                </Button>
            </div>
        </form>
    );
};

export default StudyCenterUserForm;
