import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { Switch, TextInput } from 'components';
import { MIN_QUANTITY_SELECTED_QUESTION_IN_CATEGORY } from '../../constants';
import { PATH_TO_CATEGORY_ICON_DEFAULT } from 'global-constants';

interface QuestionCategoryProps {
    id: number;
    title: string;
    quantityQuestions: number;
    iconFileLInk: string;
    defaultChecked: boolean;
    defaultQuantitySelectedQuestions?: number;
    check: (id: number) => void;
    setQuantitySelectedQuestions: (value: number, id: number) => void;
}

export const Category = (props: QuestionCategoryProps) => {
    const {
        id,
        quantityQuestions,
        defaultQuantitySelectedQuestions,
        defaultChecked,
        iconFileLInk,
        title,
        check,
        setQuantitySelectedQuestions,
    } = props;
    const {
        register,
        watch,
        setValue,
        formState: { errors },
        trigger,
    } = useFormContext();
    const [t] = useTranslation('admin-validation');

    const idName = `category.[${id}].id`;
    const switchName = `category.[${id}].checked`;
    const inputName = `category.[${id}].quantitySelectedQuestions`;

    const isChecked: boolean = watch(switchName);
    const quantitySelectedQuestions: string = watch(inputName);

    useEffect(() => {
        if (defaultQuantitySelectedQuestions) {
            setValue(inputName, defaultQuantitySelectedQuestions);
        }
    }, [defaultQuantitySelectedQuestions, inputName, setValue]);

    useEffect(() => {
        trigger(inputName);
    }, [inputName, trigger, quantitySelectedQuestions, isChecked]);

    return (
        <div className="category">
            <input type="text" defaultValue={id} hidden {...register(idName)} />
            <Switch
                defaultChecked={defaultChecked}
                reg={register(switchName, {
                    onChange: () => check(id),
                })}
            />
            <span className="category-icon">
                <img src={iconFileLInk || PATH_TO_CATEGORY_ICON_DEFAULT} alt="icon" />
            </span>
            <span>{title}</span>
            <span className="input-question-count">
                <TextInput
                    type="number"
                    id={inputName}
                    // @ts-ignore
                    errorMessage={errors.category?.[id]?.quantitySelectedQuestions.message}
                    placeholder="0"
                    onChangeHandler={(event) => setQuantitySelectedQuestions(+event.target.value, id)}
                    reg={register(inputName, {
                        validate: (value) =>
                            getQuantityQuestionsValidationMessage(value, isChecked, quantityQuestions, t) || true,
                    })}
                />
                <span className="question-count">из {quantityQuestions}</span>
            </span>
        </div>
    );
};

const getQuantityQuestionsValidationMessage = (
    value: string,
    isChecked: boolean,
    quantityQuestions: number,
    t: TFunction
) => {
    const numberValue = +value;

    if (!isChecked) {
        return;
    }

    if (numberValue === MIN_QUANTITY_SELECTED_QUESTION_IN_CATEGORY) {
        return t('number-over-value', { value: MIN_QUANTITY_SELECTED_QUESTION_IN_CATEGORY });
    }

    if (numberValue > quantityQuestions || numberValue < MIN_QUANTITY_SELECTED_QUESTION_IN_CATEGORY) {
        return t('incorrect-input');
    }

    return '';
};
