import React, { FC, useRef } from 'react';
import { useForm } from 'react-hook-form-new';
import { useTranslation } from 'react-i18next';

import {
    PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH,
    PRIVACY_STATEMENT_FULL_PATH,
    PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH,
    TERMS_OF_USE_FULL_PATH,
} from '../../global-constants';
import { StudentStudyCenterAgreements } from '../../models/student-study-center/student-study-center-agreements';
import TermsOfUse from '../../pages/application-policies/terms-of-use/TermsOfUsePage';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Column } from '../Column';
import PrivacyStatementBase from '../../pages/application-policies/privacy-statement/PrivacyBaseComponent';
import { Title } from '../Title';
import DOMPurify from 'isomorphic-dompurify';
import { language } from 'constants/language';
import { LanguageKeys } from 'models/language/language';
DOMPurify.setConfig({ ADD_ATTR: ['target'] });

interface StudentStudyCenterAgreementsProps {
    submit: (agr: StudentStudyCenterAgreements) => void;
    personalVersion: number;
    marketingVersion: number;
    currentUserPersonalVersion: number;
    currentUserMarketingVersion: number;
}

const StudentStudyCenterAgreementsNew: FC<StudentStudyCenterAgreementsProps> = ({
    submit,
    personalVersion,
    marketingVersion,
    currentUserMarketingVersion,
    currentUserPersonalVersion,
}) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<StudentStudyCenterAgreements>();

    const [t, i18n] = useTranslation(['info', 'student-study-center-agr']);
    const currentLanguage = i18n.language as LanguageKeys;

    const refMarketing = useRef<HTMLDivElement>(null);
    const refPersonal = useRef<HTMLDivElement>(null);

    const showMarketing = currentUserMarketingVersion < marketingVersion;
    const showPersonal = currentUserPersonalVersion < personalVersion;

    const onSubmit = handleSubmit(async (formValues: StudentStudyCenterAgreements) => {
        submit({
            ...formValues,
            personalCheckBoxText: t('info:personalDataChanged', {
                usingSite: `/${TERMS_OF_USE_FULL_PATH}`,
                personlLink: `/${PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH}`,
            }),
            personalText: refPersonal?.current?.innerHTML as string,
            salesForceCheckBoxText:
                currentLanguage === language.ru.key
                    ? t('info:marketing-consent-agreement-with-link', {
                          dataConsentLink: `/${PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH}`,
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      })
                    : t('info:marketing-consent-agreement2', {
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      }),
            salesForceText: refMarketing?.current?.innerHTML as string,
            marketingVersion: showMarketing ? marketingVersion : 0,
            personalVersion: showPersonal ? personalVersion : 0,
        });
    });

    return (
        <Column columnSize="100" className="code-column block-center">
            <Title titleText={t('student-study-center-agr:agreement-title')} />
            <form onSubmit={onSubmit} autoComplete="off" className="info-form">
                {showPersonal && (
                    <div className="confirm-term-of-use">
                        <Checkbox
                            additionalClasses={errors?.personalAgreement && 'error'}
                            id="personalAgreement"
                            reg={register('personalAgreement', {
                                required: {
                                    value: true,
                                    message: '',
                                },
                            })}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    t('info:personalDataChanged', {
                                        usingSite: `/${TERMS_OF_USE_FULL_PATH}`,
                                        personlLink: `/${PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH}`,
                                    })
                                ),
                            }}
                        />
                    </div>
                )}
                {showMarketing && (
                    <div className="confirm-term-of-use">
                        <Checkbox id="salesForce" reg={register('salesForce')} />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    currentLanguage === language.ru.key
                                        ? t('info:marketing-consent-agreement-with-link', {
                                              dataConsentLink: `/${PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH}`,
                                              privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                          })
                                        : t('info:marketing-consent-agreement2', {
                                              privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                                          })
                                ),
                            }}
                        />
                    </div>
                )}
                <div className="confirm-term-of-use">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                t('save-button-press', {
                                    touLink: `/${TERMS_OF_USE_FULL_PATH}`,
                                })
                            ),
                        }}
                    />
                </div>
                <div className="info-buttons d-flex justify-content-space-between">
                    <Button buttonClass="primary" type="submit">
                        {t('info:save-button')}
                    </Button>
                </div>
            </form>

            <div className="hidden" ref={refPersonal}>
                <TermsOfUse />
            </div>
            <div className="hidden" ref={refMarketing}>
                <PrivacyStatementBase />
            </div>
        </Column>
    );
};

export default StudentStudyCenterAgreementsNew;
