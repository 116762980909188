import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form-new';
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { Container, Column, Title, Paragraph, Button, Page } from 'components';
import { ApplicationState } from 'store';
import { actions } from 'pages/info/store/actions';
import SelectCountry from '../../../components/SelectCountry/SelectCountry';
import Captcha from '../../../components/Captcha/Captcha';
import { UserInfoState, UserData } from '../store/store';
import { actions as userAuthenticationActions, UserAuthenticationState } from 'authentication/UserAuthenticationStore';

import {
    PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH,
    MAX_USER_COMPANY_LENGTH,
    PRIVACY_STATEMENT_FULL_PATH,
    PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH,
} from 'global-constants';
import { TextFieldInput } from './TextFieldInput';
import { withLoading } from 'utils';
import TermsOfUse from '../../application-policies/terms-of-use/TermsOfUsePage';
import PrivacyStatementBase from '../../application-policies/privacy-statement/PrivacyBaseComponent';
import DOMPurify from 'isomorphic-dompurify';
import { AppSettingsState } from 'store/app-settings/store';
import AgreementsFormTou from '../../../components/AgreementsForm/AgreementsFormTou';
import { LanguageKeys } from 'models/language/language';
import { language } from 'constants/language';
DOMPurify.setConfig({ ADD_ATTR: ['target'] });

type ConfirmTermsOfUseFormData = UserData & {
    personalAgreement: boolean;
    marketingAgreement: boolean;
    personalCheckBoxText: string;
    personalText: string;
    marketingCheckBoxText: string;
    marketingText: string;
};

type ConfirmTermsOfUseProps = UserInfoState &
    UserAuthenticationState &
    AppSettingsState &
    typeof userAuthenticationActions &
    typeof actions;

const ConfirmTermsOfUse = (props: ConfirmTermsOfUseProps) => {
    const { user, userNameError, addUserName, loadUser, clearMessages, marketingVersion, personalVersion } = props;
    const alert = useAlert();
    const [t, i18n] = useTranslation(['info', 'validation']);
    const currentLanguage = i18n.language as LanguageKeys;
    const methods = useForm<ConfirmTermsOfUseFormData>();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = methods;

    const captchaRef = useRef<ReCAPTCHA>(null);

    const refMarketing = useRef<HTMLDivElement>(null);
    const refPersonal = useRef<HTMLDivElement>(null);

    const handleSubmitForm = handleSubmit(async (values) => {
        const captchaCode = await captchaRef?.current?.executeAsync();
        await addUserName({
            ...values,
            captchaCode: captchaCode || '',
            countryId,
            personalCheckBoxText:
                currentLanguage === language.ru.key
                    ? t('info:data-consent-agreement-with-link', {
                          dataConsentLink: `/${PRIVACY_STATEMENT_DATA_PROCESS_FULL_PATH}`,
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      })
                    : t('info:data-consent-agreement2', {
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      }),
            personalText: refPersonal?.current?.innerHTML as string,
            marketingCheckBoxText:
                currentLanguage === language.ru.key
                    ? t('info:marketing-consent-agreement-with-link', {
                          dataConsentLink: `/${PRIVACY_STATEMENT_MARKETING_PROCESS_FULL_PATH}`,
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      })
                    : t('info:marketing-consent-agreement2', {
                          privacyLink: `/${PRIVACY_STATEMENT_FULL_PATH}`,
                      }),
            marketingText: refMarketing?.current?.innerHTML as string,
            personalVersion,
            marketingVersion,
        });
        loadUser();
    });

    useEffect(() => {
        if (userNameError && alert) {
            alert.error(userNameError);
        }
        return () => {
            clearMessages();
        };
    }, [userNameError, alert, clearMessages]);

    const [countryId, setCountryId] = useState(user?.country?.id ?? 1);

    return (
        <Page additionalClass="page-content-center">
            <Container>
                <Column columnSize="100" className="block-center code-column">
                    <Title titleText={t('title')} />
                    <Paragraph additionalClass="secondary">{t('message')}</Paragraph>
                    <Captcha ref={captchaRef} />
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmitForm} autoComplete="off" className="info-form">
                            <TextFieldInput<ConfirmTermsOfUseFormData>
                                name="userName"
                                label={t('first-name')}
                                defaultValue={user?.userName ?? ''}
                                isRequired
                                register={register}
                                errors={errors}
                            />
                            <TextFieldInput
                                name="lastName"
                                label={t('last-name')}
                                defaultValue={user?.lastName ?? ''}
                                isRequired
                                register={register}
                                errors={errors}
                            />
                            <TextFieldInput
                                name="middleName"
                                label={t('middle-name')}
                                defaultValue={user?.middleName ?? ''}
                                isRequired={false}
                                register={register}
                                errors={errors}
                            />

                            <TextFieldInput
                                name="company"
                                label={t('company')}
                                defaultValue={user?.company ?? ''}
                                isRequired
                                maxLength={MAX_USER_COMPANY_LENGTH}
                                register={register}
                                errors={errors}
                            />

                            <fieldset className="form-group top-space-3x">
                                <label className="form-label">{t('country')}</label>
                                <SelectCountry onChange={setCountryId} selectedId={countryId} />
                            </fieldset>

                            <AgreementsFormTou />

                            <div className="info-buttons d-flex justify-content-space-between">
                                <Button type="submit" buttonClass="primary">
                                    {t('save-button')}
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </Column>
            </Container>
            <div className="hidden" ref={refPersonal}>
                <TermsOfUse />
            </div>
            <div className="hidden" ref={refMarketing}>
                <PrivacyStatementBase />
            </div>
        </Page>
    );
};

export default connect(
    ({ userInfo, userAuthentication, appSettings }: ApplicationState) => ({
        ...userInfo,
        ...userAuthentication,
        ...appSettings,
        isLoading: userInfo.isLoading || userAuthentication?.isLoading,
    }),
    { ...actions, ...userAuthenticationActions }
)(withLoading(ConfirmTermsOfUse) as () => JSX.Element);
