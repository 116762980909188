import React, { FC, useLayoutEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form-new';
import PrivacyStatementBase from '../../pages/application-policies/privacy-statement/PrivacyBaseComponent';
import TermsOfUse from '../../pages/application-policies/terms-of-use/TermsOfUsePage';
import AgreementsFormTou from './AgreementsFormTou';

const AgreementsForm: FC = () => {
    const { register } = useFormContext();
    const refMarketing = useRef<HTMLDivElement>(null);
    const refPersonal = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        register('personalText', {
            value: refPersonal?.current?.innerHTML as string,
        });
        register('marketingText', {
            value: refMarketing?.current?.innerHTML as string,
        });
    });

    return (
        <>
            <AgreementsFormTou />
            <div className="hidden" ref={refPersonal}>
                <TermsOfUse />
            </div>
            <div className="hidden" ref={refMarketing}>
                <PrivacyStatementBase />
            </div>
        </>
    );
};

export default AgreementsForm;
