import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Column, Container, Loader, Switch, TextInput, Title, Toggle, DatePricker } from 'components';
import { Controller, FormProvider, useForm } from 'react-hook-form-new';
import SelectByEnum from '../../../components/SelectByEnum/SelectByEnum';
import SelectDirection from '../../../components/SelectDirection/SelectDirection';
import { certificationNames } from '../../../constants/certification-names';
import { CertDirection } from '../../../enums/cert-direction';
import { CertificationEnum } from '../../../enums/certificationEnum';
import { LanguageEnum } from '../../../enums/language.enum';
import { ProductLKType } from '../../../enums/product-lk-type';
import { TestCategoryForm } from '../../../models/test/test-category-form';
import { actions } from '../store/actions';
import { TestsState } from '../store/store';
import { AssessmentInputs } from './assessment-inputs/AssessmentsInputs';
import { getAssessmentInputsData } from './assessment-inputs/getAssessmentInputsData';
import { CategoryList } from './category-list/CategoryList';
import { SettingInputs } from './setting-inputs/SettingInputs';
import { MAX_PASSING_TEST_PERCENT, MIN_MONTH_ENABLE_CERTIFICATE, MIN_PASSING_TEST_PERCENT } from './constants';
import { useTranslation } from 'react-i18next';

import { getDateZeroTimeZoneISO } from 'utils/date';
import { getAreas } from 'pages/test-areas/services/TestAreaService';
import { AutoCompliteMany } from 'components/AutoComplite/AutoCompliteMany';
import { TestArea } from 'pages/test-areas/models/AreaModels';
import { ProductGroup } from 'pages/productGroups/models/ProductGroupsModels';
import { getGroups } from 'pages/productGroups/services/ProductGroupService';

interface FormProps {
    title: string;
    onSubmit: (value: TestFormData) => void;
    goBack: () => void;
    id?: string;
    buttonSubmitTitle?: string;
    buttonCancelTitle?: string;
}

export interface TestFormData {
    id: number;
    title: string;
    directionId: CertDirection;
    quantityMinutes: string;
    quantityAttempt: string;
    isGiveCertificate: boolean;
    language: LanguageEnum;
    category: TestCategoryForm[];
    lowestPoints: string;
    lowestPointsDescription: string;
    middlePoints: string;
    middlePointsDescription: string;
    highestPoints: string;
    highestPointsDescription: string;
    minimumPercent: string;
    code?: string;
    version?: string;
    isActiveVersion: boolean;
    testType?: ProductLKType;
    testLanguage: LanguageEnum;
    certification: CertificationEnum;
    certificationMonthCount?: number;
    certificationFinishDate?: string;
    areas: TestArea[];
    groups: ProductGroup[];
    areasId?: string[];
    groupsId?: string[];
    isPercent?: boolean;
}

type TestFormProps = TestsState & typeof actions & FormProps;

export const TestForm = (props: TestFormProps) => {
    const {
        editableTest,
        editableTestCategories,
        isLoadingCategories,
        getCategories,
        onSubmit,
        goBack,
        checkTestCategory,
        setQuantitySelectedQuestions,
        title,
        buttonSubmitTitle = 'Сохранить',
        buttonCancelTitle = 'Отмена',
    } = props;

    const methods = useForm<TestFormData>({
        ///@ts-ignore
        defaultValues: {
            ...editableTest,
            directionId: editableTest.certificationDirection || CertDirection.CyberSecurity,
        },
    });
    const { handleSubmit, formState, control, watch, register, setValue, getValues } = methods;
    const { isSubmitting, errors } = formState;

    const watchDirectionId = watch('directionId');
    const watchIsGiveCertificate = watch('isGiveCertificate');
    const watchIsPercent = watch('isPercent');

    const [useFinishDate, setUseFinishDate] = useState<boolean>(
        !(!editableTest.certificationMonthCount && !editableTest.certificationFinishDate)
    );
    const [useMonthCount, setUseMonthCount] = useState(!!editableTest?.certificationMonthCount);

    useEffect(() => {
        if (!useFinishDate) {
            setValue('certificationMonthCount', undefined);
            setValue('certificationFinishDate', undefined);
        }
    }, [useFinishDate]);

    useEffect(() => {
        if (useMonthCount) {
            setValue('certificationFinishDate', undefined);
        } else {
            setValue('certificationMonthCount', undefined);
        }
    }, [useMonthCount]);

    const [t] = useTranslation('admin-validation');

    useEffect(() => {
        watchDirectionId && getCategories(watchDirectionId?.valueOf());
    }, [watchDirectionId]);

    const processData = (data: TestFormData) => {
        const category = editableTestCategories
            .filter((c) => c.checked && c.quantitySelectedQuestions > 0)
            .map(({ checked, id, quantitySelectedQuestions }) => ({
                checked,
                id,
                quantitySelectedQuestions,
            }));
        onSubmit({
            ...data,
            category,
            areasId: data.areas?.map((r) => r.id) ?? [],
            groupsId: data.groups?.map((r) => r.id) ?? [],
            areas: [] as TestArea[],
            groups: [] as ProductGroup[],
        } as TestFormData);
    };

    return (
        <Container>
            <Column columnSize="100">
                <Title titleText={title} />
            </Column>
            <Column columnSize="100">
                <FormProvider {...methods}>
                    <form autoComplete="off" onSubmit={handleSubmit(processData)}>
                        <SettingInputs editableTest={editableTest} />

                        <div className="top-space-3x">
                            <Controller
                                control={control}
                                name="certification"
                                defaultValue={editableTest.certification || CertificationEnum.CyberSecurity}
                                render={({ field: { onChange } }) => (
                                    <SelectByEnum
                                        label="Выберите сертификацию"
                                        additionalClasses="form-field"
                                        onChange={(id) => onChange(id)}
                                        enumOptions={CertificationEnum}
                                        selectedId={editableTest.certification || CertificationEnum.CyberSecurity}
                                        getOptionLabel={(key: string) => certificationNames[key]}
                                    />
                                )}
                            />
                        </div>

                        <div className="top-space-3x">
                            <Controller
                                control={control}
                                name="directionId"
                                defaultValue={editableTest.certificationDirection || CertDirection.CyberSecurity}
                                render={({ field: { onChange } }) => (
                                    <SelectDirection
                                        isDisabled={!!editableTest.id}
                                        additionalClasses="form-field"
                                        onChange={(id) => onChange(id)}
                                        selectedDirectionId={
                                            editableTest.certificationDirection || CertDirection.CyberSecurity
                                        }
                                    />
                                )}
                            />
                        </div>

                        {watchDirectionId === CertDirection.ProductLK && (
                            <>
                                <div className="top-space-3x">
                                    <Controller
                                        control={control}
                                        name="testType"
                                        defaultValue={editableTest.testType || ProductLKType.Sales}
                                        render={({ field: { onChange } }) => (
                                            <SelectByEnum
                                                selectedId={editableTest.testType || ProductLKType.Sales}
                                                label="Выберите тип теста"
                                                additionalClasses="form-field"
                                                onChange={(id) => onChange(id)}
                                                enumOptions={ProductLKType}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="top-space-3x">
                                    <TextInput
                                        type="text"
                                        defaultValue={editableTest.code}
                                        label="Код теста"
                                        id="code"
                                        reg={register('code', {
                                            required: {
                                                value: true,
                                                message: t('required'),
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: t('max-length', { maxLength: 50 }),
                                            },
                                        })}
                                        errorMessage={errors.code?.message as string}
                                    />
                                </div>
                                <div className="top-space-3x">
                                    <TextInput
                                        type="text"
                                        defaultValue={editableTest.version}
                                        label="Версия теста"
                                        id="version"
                                        reg={register('version', {
                                            maxLength: {
                                                value: 50,
                                                message: t('max-length', { maxLength: 10 }),
                                            },
                                        })}
                                        errorMessage={errors.version?.message as string}
                                    />
                                </div>
                                <div className="form-group">
                                    <Controller
                                        name="isActiveVersion"
                                        defaultValue={editableTest.isActiveVersion || false}
                                        control={control}
                                        render={() => (
                                            <Switch
                                                label="Актуальная версия"
                                                id="isActiveVersion"
                                                defaultChecked={editableTest.isActiveVersion || false}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="form-group">
                                    <Controller
                                        name="areas"
                                        control={control}
                                        render={({ field: { onChange } }) => (
                                            <AutoCompliteMany
                                                name="areas"
                                                additionalClasses="form-field"
                                                label="Область применения"
                                                id="areas"
                                                labelField="name"
                                                valueField="id"
                                                values={editableTest.areas}
                                                onChange={(e) => onChange(e ? e : undefined)}
                                                isMulty
                                                getArrAsync={(s?: string) =>
                                                    getAreas(0, 20, s).then((d) =>
                                                        d.data.items.map((m) => ({ id: m.id, name: m.name }))
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="form-group">
                                    <Controller
                                        name="groups"
                                        control={control}
                                        render={({ field: { onChange } }) => (
                                            <AutoCompliteMany
                                                additionalClasses="form-field"
                                                label="Продуктовая группа"
                                                name="groups"
                                                id="groups"
                                                labelField="name"
                                                valueField="id"
                                                values={editableTest.groups}
                                                onChange={(e) => onChange(e ? e : undefined)}
                                                isMulty
                                                getArrAsync={(s?: string) =>
                                                    getGroups(0, 20, s).then((d) =>
                                                        d.data.items.map((m) => ({ id: m.id, name: m.name }))
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}

                        {isLoadingCategories ? (
                            <Loader isBlock />
                        ) : (
                            <CategoryList
                                categories={editableTestCategories}
                                checkCategory={checkTestCategory}
                                setQuantitySelectedQuestions={setQuantitySelectedQuestions}
                            />
                        )}

                        <div className="form-group">
                            <Controller
                                name="isGiveCertificate"
                                control={control}
                                defaultValue={editableTest.isGiveCertificate || false}
                                render={({ field: { onChange } }) => (
                                    <Switch
                                        label="Выдавать сертификат"
                                        id="isGiveCertificate"
                                        defaultChecked={editableTest.isGiveCertificate || false}
                                        onChange={(e) => onChange(e)}
                                    />
                                )}
                            />
                        </div>

                        {watchIsGiveCertificate && (
                            <>
                                <div className="form-group">
                                    <Toggle
                                        label="Язык сертификата"
                                        defaultValue={
                                            editableTest.language !== undefined
                                                ? editableTest.language
                                                : LanguageEnum.Russian
                                        }
                                        firstOption={{ label: 'Русский', value: LanguageEnum.Russian }}
                                        secondOption={{ label: 'English', value: LanguageEnum.English }}
                                        reg={register('language')}
                                    />
                                </div>
                                <div className="form-group">
                                    <Checkbox
                                        isChecked={useFinishDate}
                                        label="Использовать дату окончания действия сертификата"
                                        handleChange={() => setUseFinishDate(!useFinishDate)}
                                        id="finishDate"
                                    />
                                </div>
                                {useFinishDate && (
                                    <>
                                        <div className="form-group">
                                            <Checkbox
                                                isChecked={useMonthCount}
                                                label="Использовать количество месяцев"
                                                handleChange={() => setUseMonthCount(!useMonthCount)}
                                                id="monthCount"
                                            />
                                        </div>
                                        {useMonthCount ? (
                                            <div className="form-group">
                                                <TextInput
                                                    type="number"
                                                    defaultValue={editableTest.certificationMonthCount || 1}
                                                    label="Количество месяцев"
                                                    id="certificationMonthCount"
                                                    reg={register('certificationMonthCount', {
                                                        min: {
                                                            value: MIN_MONTH_ENABLE_CERTIFICATE,
                                                            message: t('incorrect-input'),
                                                        },
                                                    })}
                                                    errorMessage={errors.certificationMonthCount?.message as string}
                                                />
                                            </div>
                                        ) : (
                                            <div className="form-group">
                                                <Controller
                                                    name="certificationFinishDate"
                                                    defaultValue={
                                                        editableTest.certificationFinishDate ||
                                                        getDateZeroTimeZoneISO(new Date(), 'start')
                                                    }
                                                    control={control}
                                                    render={() => (
                                                        <DatePricker
                                                            useZeroTime
                                                            name="certificationFinishDate"
                                                            label="Окончание действия сертификата"
                                                            returnValue="end"
                                                            value={getValues('certificationFinishDate')}
                                                            onChange={(v) =>
                                                                setValue('certificationFinishDate', v as string)
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        <div className="form-group">
                            <Controller
                                control={control}
                                name="isPercent"
                                defaultValue={editableTest.isPercent || false}
                                render={({ field: { onChange } }) => (
                                    <Switch
                                        label="Считать в процентах"
                                        id="isPercent"
                                        defaultChecked={editableTest.isPercent || false}
                                        onChange={(e) => onChange(e)}
                                    />
                                )}
                            />
                        </div>

                        {watchIsPercent ? (
                            <div className="form-group">
                                <TextInput
                                    type="number"
                                    defaultValue={editableTest.minimumPercent}
                                    label="Минимальное количество процентов"
                                    id="minimumPercent"
                                    reg={register('minimumPercent', {
                                        required: {
                                            value: true,
                                            message: t('required'),
                                        },
                                        min: {
                                            value: MIN_PASSING_TEST_PERCENT,
                                            message: t('incorrect-input'),
                                        },
                                        max: {
                                            value: MAX_PASSING_TEST_PERCENT,
                                            message: t('max-passing-test-percent', { max: MAX_PASSING_TEST_PERCENT }),
                                        },
                                    })}
                                    errorMessage={errors.minimumPercent?.message as string}
                                />
                            </div>
                        ) : (
                            <AssessmentInputs
                                defaultValue={getAssessmentInputsData(editableTest)}
                                categories={editableTestCategories}
                            />
                        )}

                        <Button buttonClass="primary" type="submit" isDisabled={isSubmitting}>
                            {buttonSubmitTitle}
                        </Button>
                        <Button handleClick={goBack} buttonClass="secondary">
                            {buttonCancelTitle}
                        </Button>
                    </form>
                </FormProvider>
            </Column>
        </Container>
    );
};
